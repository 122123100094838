import Image from 'next/image'
import Link from 'next/link'
import { AppRoutes } from 'src/lib/routes'
import { NextSeo } from 'next-seo'
import Illu from'public/images/illu_2.png';
import ImageWithFallback from 'src/components/ImageWithFallback';

const metaTitle = "404 Not found"

export async function getStaticProps() {
  return {
    props: {},
  };
}

const Error404 = () => {
  return (
    <>
      <NextSeo
        title={metaTitle}
        noindex={true}
        nofollow={true}
      />
      <div className="tw-bg-brand-yellow">
        <div className="container tw-py-20 md:tw-py-40 tw-text-center">
          <ImageWithFallback src={Illu} alt="" className="tw-static tw-inline-block tw-w-40 tw-h-auto tw-mb-8 tw-object-cover" />
          <div className="tw-font-styleSans tw-font-semibold tw-text-2xl md:tw-text-4xl tw-leading-none md:tw-leading-none tw-mb-4">Oops missing page!</div>
          <div className="tw-mb-2">Here are some helpful links instead:</div>
          <div className="tw-mb-2">
            <Link legacyBehavior prefetch={false} href={AppRoutes.contactRoute().toPath()}>
              <a className="tw-font-semibold tw-underline tw-text-brand-navy">contact us</a>
            </Link>
          </div>
          <div>
            <Link legacyBehavior prefetch={false} href={AppRoutes.rootRoute().toPath()}>
              <a className="tw-font-semibold tw-underline tw-text-brand-navy">homepage</a>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Error404
